<template>
    <div class="container">
        <div class="logo">
            <el-image :src="logo"></el-image>
        </div>

        <div class="page-header">
            <h1 class="page-title">{{ $t('register.title.page') }}</h1>
        </div>

        <div class="step-box">
            <el-steps :active="activeStep" finish-status="success" align-center>
                <el-step :title="$t('register.step.informations')"></el-step>
                <el-step :title="$t('register.step.documents')"></el-step>
                <el-step :title="$t('register.step.categories')"></el-step>
            </el-steps>
        </div>

        <div v-if="activeStep == 0" class="white-box informations" v-loading="loading">
            <informations
                v-if="registerRequest != null && Object.keys(registerRequest).length > 0"
                @nextStep="nextStep"
                ref="informations"
            ></informations>
        </div>

        <div v-else-if="activeStep == 1" class="white-box documents" v-loading="loading">
            <documents @nextStep="nextStep" ref="documents"></documents>
        </div>

        <div v-else-if="activeStep == 2" class="white-box categories-mapping" v-loading="loading">
            <categories ref="categories"></categories>
        </div>

        <div class="white-box">
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12">
                    <div class="info-text">Campurile marcate cu <span class="required">*</span> sunt obligatorii</div>
                </el-col>
                <el-col :xs="24" :sm="12" class="right">
                    <el-button :loading="loading" class="btn btn-next-step" @click="nextStep()">
                        {{ activeStep == 2 ? $t('register.step.finish') : $t('register.step.next-step') }}</el-button
                    >
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { createLeadsStore } from '@/store/modules/leads/index.js';
import logoImg from '@/assets/images/lead/logo.png';

const Informations = () => import('./components/informations');
const Documents = () => import('./components/documents');
const Categories = () => import('./components/categories');

export default {
    name: 'Register',
    metaInfo() {
        return {
            meta: [{ name: 'robots', content: 'noindex' }]
        };
    },
    components: {
        Informations,
        Documents,
        Categories
    },
    props: ['token'],
    data() {
        return {
            loading: false,
            logo: logoImg
        };
    },
    methods: {
        getRegisterRequest() {
            this.$store
                .dispatch('leads/GetRegisterRequest')
                .then()
                .catch((err) => {
                    this.$reportToSentry(err, {
                        extra: {
                            fn: 'getRegisterRequest'
                        }
                    });
                    this.$router
                        .push({
                            path: '/'
                        })
                        .catch(() => {});
                });
        },
        nextStep() {
            this.loading = true;

            if (this.activeStep == 0) {
                this.$refs.informations
                    .nextStep()
                    .then((res) => {
                        this.loading = false;
                        this.$store.commit('leads/SET_ACTIVE_STEP', this.activeStep + 1);
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.message != null && err.message != 2) {
                            this.$reportToSentry(err, {
                                extra: {
                                    fn: 'nextStep',
                                    params: {
                                        activeStep: 0
                                    }
                                }
                            });
                        }
                    });
            } else if (this.activeStep == 1) {
                this.$refs.documents
                    .nextStep()
                    .then((res) => {
                        this.loading = false;
                        this.$store.commit('leads/SET_ACTIVE_STEP', this.activeStep + 1);
                    })
                    .catch((err) => {
                        this.$reportToSentry(err, {
                            extra: {
                                fn: 'nextStep',
                                params: {
                                    activeStep: 1
                                }
                            }
                        });
                        this.loading = false;
                    });
            } else if (this.activeStep == 2) {
                this.$refs.categories
                    .nextStep()
                    .then((res) => {
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.$reportToSentry(err, {
                            extra: {
                                fn: 'nextStep',
                                params: {
                                    activeStep: 2
                                }
                            }
                        });
                        this.loading = false;
                    });
            }
        }
    },
    computed: {
        ...mapState({
            activeStep: (state) => state.leads.activeStep,
            registerRequest: (state) => state.leads.registerRequest,
            cities: (state) => state.leads.cities,
            captchaDialog: (state) => state.leads.captchaDialog
        })
    },
    async mounted() {
        localStorage.removeItem('lastVisited');
        try {
            await this.$store.dispatch('user/LogOut', {
                withRequest: false
            });
        } catch (e) {
            this.$reportToSentry(e, {
                extra: {
                    fn: 'logout'
                }
            });
        }

        this.$store.commit('leads/SET_TOKEN', this.token);
        this.getRegisterRequest();
    },
    beforeCreate() {
        this.$store.registerModule('leads', createLeadsStore());
    },
    beforeDestroy() {
        this.$store.unregisterModule('leads');
    }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.container {
    width: 100%;
    max-width: 1250px;
    margin: auto;
}

.logo {
    margin-top: 30px;
    padding: 10px;
    text-align: center;
}

::v-deep .logo img {
    max-width: 100%;
    height: auto;
}

.page-header {
    margin-top: 30px;
    background: #f17146;
    padding: 20px;
    padding-bottom: 16px;
    border-radius: 4px;

    .page-title {
        font-size: 24px;
        font-weight: 400;
        margin: 0;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
    }
}

.white-box {
    padding: 30px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 30px;
    -webkit-box-shadow: 2px 1px 3px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 1px 3px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 2px 1px 3px 0 rgba(0, 0, 0, 0.2);

    &.categories-mapping {
        min-height: 650px;
    }

    &.informations {
        min-height: 800px;
    }
}

.step-box {
    margin-top: 25px;
    margin-bottom: 20px;
}

::v-deep .el-form-item__label {
    padding-bottom: 0;
    line-height: 30px;
}

::v-deep .el-form-item--small.el-form-item {
    padding-bottom: 10px;
    margin-bottom: 0px;
}

::v-deep .el-form-item.is-error {
    padding-bottom: 22px;
}

::v-deep .el-form-item--small .el-form-item__error {
    padding-top: 5px;
}

.el-form-item + .box-title {
    margin-top: 25px;
}

span.required {
    color: #f00;
}

.btn.btn-next-step {
    cursor: pointer;
    background: #f17146;
    border-color: #f17146;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    padding: 7px 20px;
    border-radius: 0;
}

.right {
    text-align: right;
}

.info-text {
    font-size: 14px;
    margin-top: 12px;
}
</style>
