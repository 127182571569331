import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = () => ({
	token: '',
	activeStep: 0,
	registerRequest: {},
	cities: {}
})

export function createLeadsStore() {
	return {
		namespaced: true,
		state: initialState,
		actions,
		getters,
		mutations,
	};
}