export default {
	SET_TOKEN: (state, data) => {
		state.token = data;
	},
	SET_ACTIVE_STEP: (state, data) => {
		state.activeStep = data;
	},
	SET_REGISTER_REQUEST: (state, data) => {
		state.registerRequest = data;
	},
	SET_CITIES: (state, data) => {
		state.cities = data;
	},
	SET_ACTS: (state, data) => {
		state.acts = data
	}
}