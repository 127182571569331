import { getRegisterRequest, getRegisterCities, checkUsername, checkName, checkIBAN, addAccount } from '@/api/lead';
import { saveAffiliateContact, getAffiliateActs, uploadActFile, removeActFile } from '@/api/cont';

export default {
    GetRegisterRequest: ({ commit, state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getRegisterRequest({
                    token: state.token
                });
                if (res.message == null) return reject(new Error());
                commit('SET_REGISTER_REQUEST', res.message);
                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    },
    AddAccount: ({ commit, state }, form) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await addAccount({
                    token: state.token,
                    informations: form
                });
                if (res.message == null) {
                    return reject(new Error());
                }
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    CheckUsername: ({ commit, state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await checkUsername({
                    username: data.username
                });
                if (res.message == null) {
                    return reject(new Error());
                }
                return resolve(res.message);
            } catch (err) {
                return reject(err);
            }
        });
    },
    CheckName: ({ commit, state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await checkName({
                    name: data.name
                });
                if (res.message == null) {
                    return reject(new Error());
                }
                return resolve(res.message);
            } catch (err) {
                return reject(err);
            }
        });
    },
    CheckIBAN: ({ commit, state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await checkIBAN({
                    iban: data.iban
                });
                if (res.message == null) {
                    return reject(new Error());
                }
                return resolve(res.message);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetRegisterCities: ({ commit, state }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getRegisterCities({
                    county: data.county
                });
                if (res.message == null) {
                    return reject(new Error());
                }
                commit('SET_CITIES', res.message);
                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    },
    SaveAffiliateContact: ({ commit }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await saveAffiliateContact(data);
                if (res.message == null) {
                    return reject(new Error());
                }
                // commit('SET_ACTS', res.message)
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetAffiliateActs: ({ commit }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getAffiliateActs();
                if (res.message == null) {
                    return reject(new Error());
                }
                // commit('SET_ACTS', res.message)
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    UploadActFile: ({ commit }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await uploadActFile(data);
                if (res.message == null) {
                    return reject(new Error());
                }
                // commit('SET_ACTS', res.message)
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    RemoveActFile: ({ commit }, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await removeActFile(data);
                if (res.message == null) {
                    return reject(new Error());
                }
                // commit('SET_ACTS', res.message)
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    }
};
